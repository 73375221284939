<template>
  <div>
    <RouterView></RouterView>
  </div>
</template>

<script setup>
import { state, changeLanguage } from './api';
import { provide } from "vue";

//cung cập các biến cho toàn bộ app;
provide('apiState', state);
provide('changeLanguage', changeLanguage);
</script>

<style scoped>

</style>