import { reactive } from "vue";
import axios from "axios";

const defaultLanguage = getCountry();
export const state = reactive({
  language: defaultLanguage || 'en',
});

export function changeLanguage(newLanguage) {
  state.language = newLanguage;
  localStorage.setItem('lang', newLanguage);
}

function getCountry() {
  const savedLanguage = localStorage.getItem('lang');
  if (savedLanguage) {
    return savedLanguage;
  }
  // try {
  //   const response = await axios.get('https://ip2c.org/s');
  //   if (response?.data) {
  //     const result = (response.data || '').toString();
  //     if (result && result.substring(2, 4) == "VN") {
  //       return "vi";
  //     } else {
  //       return "en"
  //     }
  //   } else {
  //     return 'en'
  //   }
  // } catch (error) {
    return 'en';
  // }
}