import { createApp } from 'vue'
// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap"
import "./scss/custom.scss"
import router from './router';
import './style.css'
import App from './App.vue'

createApp(App)
  .use(router)
  .mount('#app')
