import { createRouter, createWebHistory } from "vue-router";
// import { authStore } from "../stores/authStore";

const routes = [
  {
    path: "",
    component: () => import("../views/Layout.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../views/Home.vue")
      },
      {
        path: "/services",
        name: "Services",
        component: () => import("../views/Service.vue")
      },
      {
        path: "/solutions",
        name: "Solutions",
        component: () => import("../views/Solution.vue")
      },
      {
        path: "/about",
        name: "About",
        component: () => import("../views/About.vue")
      },
      {
        path: "/contact",
        name: "Contact",
        component: () => import("../views/Contact.vue")
      },
      // {
      //   path: "/:name",
      //   name: "Page",
      //   component: () => import("../views/Page.vue")
      // },
      {
        path: "/contact",
        name: "Contact",
        component: () => import("@/views/Contact.vue")
      }

    ],
  },
  // {
  //   path: "/services",
  //   name: "Services",
  //   component: () => import("../views/Service.vue")
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
});

export default router